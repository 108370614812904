<template>
    <div class="row card-container">
        <div class="col">
            <div class="form card card-body responsive">
                <h1>{{ $t("changePassword.default.changePasswordHeader") }}</h1>
                <div class="form-group mx-auto">
                    <label for="inputPassword">{{ $t("changePassword.default.labelNewPassword") }}</label>
                    <input class="form-control mt-2" id="inputPassword" type="password" v-model="newPassword" :placeholder="$t('firstTime.placeholderNewPassword')">
                    <div class="form-error" id="err_inputPassword" v-show="errors['newPassword']">{{ errors['newPassword'] }}</div>
                </div>
                <div class="form-group mx-auto">
                    <label for="inputPasswordConfirm">{{ $t("changePassword.default.labelConfirmNewPassword") }}</label>
                    <input class="form-control mt-2" id="inputPasswordConfirm" type="password" v-model="newPasswordConfirm">
                    <div class="form-error" id="err_inputComfirm" v-show="errors['newPasswordConfirm']">{{ errors['newPasswordConfirm'] }}</div>
                </div>
                <div class="btn btn-primary mt-5 font-weight-bold mx-auto" v-on:click="doChange">{{ $t("changePassword.default.btnSave") }}
                </div>
                <div class="btn cancel mt-3 font-weight-bold mx-auto" @click="$router.push('Login')">{{ $t("changePassword.default.btnCancel") }}
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { SetResetPasswordFinish } from "../assets/jsipclient/index";
import Common from "../assets/jsipclient/common";
import { mapGetters } from "vuex";

export default {
  name: "ChangePassword",
  data() {
    return {
      newPassword: '',
      newPasswordConfirm: '',
      errors: []
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    async doChange(step) {
      this.errors = [];
      try {
        const api = Common.createLoginApi()
        var params = new SetResetPasswordFinish()
        params.newPassword = this.newPassword
        params.newPasswordConfirm = this.newPasswordConfirm
        params.token = this.$route.query['t']
        
        const jsonObject = await api.loginResetPasswordFinishPostAsync(params)

        const message =  this.$t("changePassword.default.toastSuccessChangePassword")
        Common.showToast(message)

        this.$router.push({name: 'Login'})
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
  }
}
</script>

<style lang="scss" scoped>

    .form-control {
        width: 255px;
        background-color: #F2ECF0;
        border: 1px solid #F2ECF0;
    }

    .cancel {
        width: 100%;
        color: #333333;
        border: none;
        background-color: #FFFFFF;
    }

    .cancel:active {
        color: #C95D6C;
        background-color: #FFFFFF;
    }

    label {
        font-size: smaller;
    }
</style>
